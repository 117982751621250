.wrapper_historique {

  background: -webkit-linear-gradient(91deg, #97C11D 10%, #FCDC01 90%);
  background: -moz-linear-gradient(91deg, #97C11D 10%, #FCDC01 90%);
  background: -ms-linear-gradient(91deg, #97C11D 10%, #FCDC01 90%);
  background: -o-linear-gradient(91deg, #97C11D 10%, #FCDC01 90%);
  background: linear-gradient(91deg, #97C11D 10%, #FCDC01 90%);

}


.wrapper_historique_header {
  color: white;
  padding-top: 30px;
  padding-left:20px;
}


.containersHistorique {

  color: #005397;
  font-weight: 400;
  background-color: white;
  border-radius: 35px 35px 0px 0px;
  margin-top: 20px;
  padding-top: 2px;
  padding-left: 20px;
  padding-right: 20px;

}


.historique_table .ant-table-thead .ant-table-cell {
  background-color: transparent !important;
  color : #005397;
  font-size: 10px;
  font-weight: 600;
  border: none !important;
  width: auto;
}

.ant-table-cell {
  color : #005397;

}
