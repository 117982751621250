.wrapper_analyse {

  background: -webkit-linear-gradient(91deg, #005397 10%, #1997D4 90%);
  background: -moz-linear-gradient(91deg, #005397 10%, #1997D4 90%);
  background: -ms-linear-gradient(91deg, #005397 10%, #1997D4 90%);
  background: -o-linear-gradient(91deg, #005397 10%, #1997D4 90%);
  background: linear-gradient(91deg, #005397 10%, #1997D4 90%);

}

.analyse_wrapper {
  margin-left: 15px;
  margin-right: 15px;
  max-width: 1200px;
}

.bassin_wrapper {
  background-color: white;
  background-color: white;
  border-radius: 35px 35px 0px 0px;
  margin-top: 20px;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
}

.analyse_text_containeur {

  background-color: #aeddf0;
  border-radius: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  color : #005397;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 5px;
  margin-bottom: 5px;

}

.analyse_text_containeur span {
  font-weight: 700;
}

.votreBassinImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.analyse_Titre {
    font-size: 20px;
    color: #005397;
    font-weight: 600;
}

.equilibre_cell {
  text-align: center;
  margin : auto;
  position: relative;
  display: inline-block;
}

.equilibre_cell_text {
  color : #005397;
  text-align: center;
  margin-top: 2px;

}

.equilibre_cell_text span{
  font-weight: 600;
}

.analyse_text_containeur .resumeTitre {
    font-weight: 700;
}

.equilibre_cell_imageTitre1 {
  color : #005397;
  text-align: center;
  position: absolute;
  text-align: center;
  height: 100%;
  width: 100%;
  margin-top: 35px;
  font-weight: 650;
  font-size: 18px;
}

.equilibre_cell_imageTitre2 {
  color : #005397;
  text-align: center;
  position: absolute;
  text-align: center;
  height: 100%;
  width: 100%;
  margin-top: 85px;
  font-weight: 650;
  font-size: 15px;
  color: white;
}

.analyseResume_border {
   padding : 1px;
   background-color: #0093CF;
   margin-bottom: 10px;
}


.analyseResume_border_text {
  font-size: 11px;
  font-weight: 600;
  margin-top: 5px;
}
