.wrapper_home {
  margin: auto;
  text-align: center;
  max-width: 1000px;
  background: #1B9BD7;
  border-bottom: white;
}



h2 {
  color: $white;
}




.containersHome {

  color: #005397;
  font-weight: 400;
  background-color: white;
  border-radius: 35px 35px 0px 0px;
  margin-top: 20px;
  padding-top: 2px;
}




.scrolling-wrapper-flexbox {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  .card {
    flex: 0 0 auto;
    margin-right: 3px;
  }
}

.card {
  width: 150px;
  height: 190px;
}

.colorCard {
  font-weight: 600;
}

.conseilWrapp{
  background-color: white;
}




.scrolling-wrapper, .scrolling-wrapper-flexbox {
  height: 100%;
  margin-bottom: 20px;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    display: none;
  }
}

.scrolling-wrapper-flexbox img {
  padding: 8px;
  width: 150px;
  height: 150px;
  border-radius: 30%;

}



.wrapper_home_header {
  color: white;
  padding-top: 30px;
  padding-left:20px;
  height: 60px;
}

.homeMenuCell {
  text-align: center;
  margin: auto;
}

.homeMenuCell img {
  text-align: center;
  margin: auto;
}



.home_titre {
  font-weight: 700;
  font-size : 25px;
  text-align: left;
  color: #005397;
  margin-left: 10px;
}


div.scroll-container {
  overflow: auto;
  white-space: nowrap;
}

div.scroll-container img {
  padding: 8px;
  width: 150px;
  height: 150px;
  border-radius: 30%;
  margin-bottom: 50px;
}


.container {
  max-width: 500px;
  margin: 0 auto;
}

 .image-container {
   display: flex;
   overflow-x: scroll;
   padding: 0 20px;
   /* Add padding to the left and right sides */
 }

 .image-wrapper {
   margin: 0 5px;
    border-radius: 20%;
 }

 .image-wrapper img {
   width: 150px;
   height: 150px;

 }

 .image-wrapper p {
   background-color: #1B9BD7;
   color: white;
   padding-top: 10px;
   padding-bottom: 10px;
   margin-top: -30px;
   z-index: 2;
 }



 @media all and (min-width: 200px) {

   .homeMenuCell img {
     width : 100px;
     height:  100px;
   }

 }

 @media all and (min-width: 280px) {

   .homeMenuCell img {
     width : 120px;
     height:  120px;
   }

 }


 @media all and (min-width: 350px) {

   .homeMenuCell img {
     width : 180px;
     height:  180px;
   }

 }
