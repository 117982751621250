
.navbar {
  overflow: hidden;
  background-color: transparent;
  position: fixed;
  bottom: 0;
  width: 100%;
  color: transparent;
  height:160px;
}

.navbar a {

  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 14px;
  text-decoration: none;
  font-size: 10px;
}

.navbar a:hover {
  color: transparent;
}

.navbar a.active {
  color: white;
}


.container1 {
  display: flex;
  width: 100%;
}

.element1 {
  flex: 1;
  margin-top: 80px;
  text-align: center;
}


@media screen and (max-width: 900px) {

  .navbar {
    height: 118px;
  }

  .element1 {
    margin-top: 40px;
  }

}


@media screen and (max-width: 800px) {

  .navbar {
    height: 115px;
  }

  .element1 {
    margin-top: 40px;
  }

}


@media screen and (max-width: 750px) {

  .navbar {
    height: 115px;
  }

  .element1 {
    margin-top: 40px;
  }

}


@media screen and (max-width: 650px) {

  .navbar {
    height: 110px;
  }

  .element1 {
    margin-top: 30px;
  }

}

@media screen and (max-width: 500px) {

  .navbar {
    height: 95px;
  }

  .element1 {
    margin-top: 17px;
  }

}
