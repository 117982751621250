.wrapper_analyse {

  background: -webkit-linear-gradient(91deg, #005397 10%, #1997D4 90%);
  background: -moz-linear-gradient(91deg, #005397 10%, #1997D4 90%);
  background: -ms-linear-gradient(91deg, #005397 10%, #1997D4 90%);
  background: -o-linear-gradient(91deg, #005397 10%, #1997D4 90%);
  background: linear-gradient(91deg, #005397 10%, #1997D4 90%);
  max-width: 1200px;

}


.wrapper_analyse_header {
  color: white;
  padding-top: 30px;
  padding-left:20px;

}

.wrapper_analyse_header_Title {
  color: white;
  font-size: 28px;
  font-weight: 700;
  margin-left: 63px;
  margin-top: -45px;
}

.header_image_goutte {
  text-align: right;
  margin-top: -70px;
  margin-right: 20px;
}


.containersFormulaire {
  color: #005397;
  font-weight: 400;
  max-width: 1280px;
  background-color: white;
  border-radius: 35px 35px 0px 0px;
  margin-top: 20px;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;

}

.wrapper_analyse .referenceAnalyse {
  margin-left: 4%;
}

.wrapper_analyse .proAnalyse {
  font-size: 11px;
  text-align: right;
  margin-right: 5%;
  margin-top: 5px;

}

.wrapper_analyse .debutSaisonAnalyse {
  font-size: 11px;
  margin-right: 5%;
  margin-top: 20px;

}

.ant-switch-checked{
   background:#0093CF !important;
}



.containersFormulaire h4 {
  font-size: 20px;
}

.containersFormulaire h5 {
  font-size: 17px;
}


.checkBoxForm {
  color: #005397;
  font-weight: 450;
}

.checkBoxFormSpan {
  color: #005397;
  font-weight: 200;
  font-size: 40;
  display: block;
}

.checkBoxFormSpan span{
  font-size: 8;
}

.valeur_mesuree {
  margin-left: 10px;
  margin-right: 10px;
}


.valeur_mesuree_item {
  margin-top: -20px;
}

.valeur_mesuree_left {
  border-radius: 8px 0px 0px 8px;

}

.valeur_mesuree_right {
  border-radius: 0px 8px 8px 0px;
}



.containersFormulaire .fondFormulaire {
  margin-top: -20px;
  background-color: #AEDDF0;
  padding-top:8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 400;
  font-size: 30px;


}



.fondFormulaire p {
  font-size: 16px;
  font-weight: 600;
}



.ant-slider-track {
  display: none;
}

.ant-slider-rail {
  display: none;
}

.buttonForm{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.buttonFormStyle Button{
  margin-bottom: 16px;
  background-color:  '#0093CF';
  color:'white';

}






/*
.ant-col .resteAPayer_wrapper {
  margin: 0 auto;
}

.resteAPayer_wrapper .resteAPayer {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
  font-size: 20px;
  max-width: 150px;
  margin: 0 auto;
  border-radius: 5px;
}

.resteAPayer_wrapper .resteAPayerRegle {
  background-color: green;
}

.resteAPayer_wrapper .montantTotal {
  text-align: center;
  margin-top: 5px;
  font-size: 12px;

}

.modeReglement_wrapper {
  background: white;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
  cursor: pointer;

}

.modeReglement_wrapper .item {

  padding-left: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
  padding-right: 15px;
  text-align: center;
  border-radius: 4px;
  background: #363642;
  margin: 2px;

  max-height: 50px;
  margin-top: 0 auto;
  margin-bottom: 0 auto;
  width: 100%;

}

.modeReglement_wrapper .item:hover {
    background-color:
}
*/
