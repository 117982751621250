.wrapper_produits {

  background: -webkit-linear-gradient(91deg, #FF8C00 10%, #FFD100 90%);
  background: -moz-linear-gradient(91deg, #FF8C00 10%, #FFD100 90%);
  background: -ms-linear-gradient(91deg, #FF8C00 10%, #FFD100 90%);
  background: -o-linear-gradient(91deg, #FF8C00 10%, #FFD100 90%);
  background: linear-gradient(91deg, #FF8C00 10%, #FFD100 90%);

}


.analyse_wrapper {
  margin-left: 15px;
  margin-right: 15px;
  max-width: 1200px;
}

.bassin_wrapper {
  background-color: white;
  background-color: white;
  border-radius: 35px 35px 0px 0px;
  margin-top: 20px;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
}

.analyse_text_containeur {

  background-color: #aeddf0;
  border-radius: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  color : #005397;
  font-weight: 400;
  line-height: 1.2;

}

.analyse_text_containeur span {
  font-weight: 700;
}


.votreBassinImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.analyse_Titre {
    font-size: 20px;
    color: #005397;
    font-weight: 600;
}


.traitementAnalyse_cell {
  font-weight: 700;
  text-align: center;
  font-size: 18px;
  color: #005397;
}


.analyse_text_containeur .resumeTitre {
    font-weight: 700;
}



 @media all and (min-width: 390px) {

   .traitementAnalyse_cell img {
     width : 100%;
     height: 100%;
   }

 }


 @media all and (min-width: 500px) {

   .traitementAnalyse_cell img {
     width : 90%;
     height: 90%;
   }

 }
