.wrapper_analyse {

  background: -webkit-linear-gradient(91deg, #005397 10%, #1997D4 90%);
  background: -moz-linear-gradient(91deg, #005397 10%, #1997D4 90%);
  background: -ms-linear-gradient(91deg, #005397 10%, #1997D4 90%);
  background: -o-linear-gradient(91deg, #005397 10%, #1997D4 90%);
  background: linear-gradient(91deg, #005397 10%, #1997D4 90%);

}


.bassin_wrapper {
  background-color: white;
  background-color: white;
  border-radius: 35px 35px 0px 0px;
  margin-top: 20px;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
}


.bassin_wrapper_percent{
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;

}

.traitementModeEmploi_cell {

  text-align: left;
  font-size: 18px;
  color: #005397;

  border-radius: 15px;
  padding-left: 17px;
  padding-right: 17px;
  padding-top: 1px;
  padding-bottom: 1px;
  margin-top: 5px;

}


.traitementModeEmploi_cell .titreModeEmploi {
  opacity: 1;
  font-weight: 650;
  letter-spacing: -1px;
  font-size: 17px;
}


.traitementModeEmploi_cell .descriptionModeEmploi {
  opacity: 1;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -1px;
}


.carousselTraitement {
  margin-top: 15px;
  border: solid;
  border-radius: 15px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}


.carousselTraitementLeft {
    margin-right: 50px;
}

.carousselTraitementRight {
    margin-left: 50px;
}

.traitementsValeurLeftTitre{
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.traitementsValeurLeft {
  padding-bottom:0px;
  border-radius: 10px;
  color: white;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  margin-right: 5px;
  margin-left: 10px;
}
.traitementsValeurRight {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top:5px;
  padding-bottom:5px;
  border-radius: 10px;
  color: white;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  margin-right: 5px;
  margin-left: 10px;
  height: 100%;
}
