.wrapper_simulateur {

  background: -webkit-linear-gradient(91deg, #E2037F 10%, #8C5C99 90%);
  background: -moz-linear-gradient(91deg, #E2037F 10%, #8C5C99 90%);
  background: -ms-linear-gradient(91deg, #E2037F 10%, #8C5C99 90%);
  background: -o-linear-gradient(91deg, #E2037F 10%, #8C5C99 90%);
  background: linear-gradient(91deg, #E2037F 10%, #8C5C99 90%);

}


.wrapper_simulateur_header {
  color: white;
  padding-top: 30px;
  padding-left:20px;
}


*:not(input) {
  user-select: none;
}

.simulateurContaineur {
    margin: auto;
}


.simulateurFormeRow {

}


.wrapper_analyse_header {
  color: white;
  padding-top: 30px;
  padding-left:20px;
}



.containersSimulateurFormulaire {

  color: #005397;
  font-weight: 400;
  background-color: white;
  border-radius: 35px 35px 0px 0px;
  margin-top: 20px;
  padding-top: 2px;
  padding-left: 20px;
  padding-right: 20px;

}

.simulateurFormeCell {
  text-align: center;
  background-color: #B2D8EB;
  border-radius: 10px;

}

.selectCell {
  border: solid 0.2em;
}

.simulateurFormeCell .UnselectCell {
  border: 0;
}

.simulateurFormeCell p {
  font-weight: bold;
  white-space: pre-line;
}

.CalculerVolumeTitre {
  font-size: 17px;
}

.CalculerVolumeTitre span {
  font-weight: bold;
}

.CalculerVolumeTitre1 {
  font-size: 22px;
  font-weight: 700;
}

.CalculerVolumeTitre2 {
  margin-top: -19px;
  font-size: 18px;
  font-weight:400;
}

.dimension_bassin {
  text-align: center;
  background-color: white;
  margin-bottom: 10px;
}

.input_calculVolume {
  border-color: #005397;
  margin-top: 2px;
}

.image_schema {
  margin-top: 20px;
}

.result_simulateur  {

  background-color: #B2D8EB;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 80%;
  margin: auto;
  margin-top: 25px;

}

.result_simulateur .titre1 {
  font-weight: 700;
  font-size: 15px;

}

.result_simulateur .titre2 {
  font-weight: 700;
  font-size: 25px;

}
